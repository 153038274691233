<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
#app {
  height: 100vh;

  font-family: 'Chakra Petch', sans-serif !important;
  background: #eff3f8;
}

.p-component, .p-inputtext{
  font-family: 'Chakra Petch', sans-serif !important;
}

.hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgb(240, 239, 239);
}

::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
  border-radius: 5px;
}
</style>
