<template>
  <div class="body-page">
    <h6 class="fw-bold">Histórico</h6>
    <div class="d-flex justify-content-end my-3">
      <Button @click="getLogs()" icon="pi pi-sync" class="p-button-warning p-button-sm me-2" />
    </div>

    <Table :logs="logs" :loading="loading" />
  </div>
</template>

<script>
import Table from '@/components/pages/logs/Table'
import Button from 'primevue/button'

export default {
  name: 'Logs',

  components: {
    Table,
    Button
  },

  data() {
    return {
      logs: [],
      loading: true
    }
  },

  methods: {
    async getLogs() {
      this.loading = true
      
      await this.$api.get('/logs')
        .then((response) => {
          this.logs = response.data.logs
          this.loading = false
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    }
  },

  created() {
    this.getLogs()
  }
}
</script>

<style lang="scss">
.body-page {
  width: 100%;

  padding: 20px;

  border-radius: 10px;
  border: 1px solid gainsboro;
  background: white;
}
</style>