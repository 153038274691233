<template>
  <Dialog header="Excluir Ganhadores" :visible.sync="display" :containerStyle="{ width: '400px' }" :modal="true">
    
    <div class="d-flex align-items-center my-4">
      <i class="pi pi-exclamation-triangle text-danger me-3" style="font-size: 2rem"></i>
      <p>Deseja excluir os ganhadores do módulo <b>{{ winnerModule.title }}</b> [ID: {{ winnerModule.id }}] ?</p>
    </div>
    
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" @click="closeModal()" class="p-button-sm p-button-secondary" />
      <Button :label="buttons.delete.label" icon="pi pi-trash" @click="deleteWinners(winnerModule.id)" class="p-button-sm p-button-danger" :disabled="buttons.delete.disabled" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'

export default {
  name: 'ModalDelete',

  components: {
    Dialog,
    Button
  },

  data() {
    return {
      winnerModule: {},

      buttons: {
        delete: {
          label: 'Excluir',
          disabled: false
        }
      },

      display: false
    }
  },

  methods: {
    async deleteWinners(id) {

      this.buttons.delete.label = 'Excluindo...'
      this.buttons.delete.disabled = true

      await this.$api.delete(`/winner_modules/${id}/winners`)
        .then(() => {
          this.$parent.$parent.getWinners()
          this.$toastr.s('Ganhadores excluídos com sucesso!', 'Sucesso')
          this.closeModal()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
        .finally(() => {
          this.buttons.delete.label = 'Excluir'
          this.buttons.delete.disabled = false
        })
    },

    openModal(data) {
      this.winnerModule = data
      this.display = true
    },

    closeModal() {
      this.display = false
    }
  }
}
</script>

<style></style>