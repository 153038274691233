<template>
  <div class="body-page">
    <h6 class="fw-bold">[{{ organization.name }}] Lista de promoções</h6>
    <div class="d-flex justify-content-end my-3">
      <Button @click="goToOrganizations()" label="Voltar" icon="pi pi-arrow-left" class="p-button-secondary p-button-sm me-2" />
      <Button @click="getPromotions()" icon="pi pi-sync" class="p-button-warning p-button-sm me-2" />
      <Button @click="openModalAdd(organization)" label="Adicionar Promoção" icon="pi pi-ticket" class="p-button-success p-button-sm" />
    </div>

    <Table :promotions="promotions" :loading="loading" :organization="organization" />
    <ModalAdd ref="modalAdd" />
  </div>
</template>

<script>
import Table from '@/components/pages/promotions/Table'
import Button from 'primevue/button'
import ModalAdd from '@/components/pages/promotions/ModalAdd'

export default {
  name: 'Organization',

  props: ['id'],

  components: {
    Table,
    Button,
    ModalAdd
  },

  data() {
    return {
      organization: {},
      promotions: [],
      loading: true
    }
  },

  methods: {
    async getPromotions() {
      this.loading = true

      await this.$api.get(`/organizations/${this.id}/promotions`)
        .then((response) => {
          this.promotions = response.data.promotions
          this.loading = false
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    },

    async getOrganization() {
      await this.$api.get(`/organizations/${this.id}`)
        .then((response) => {
          this.organization = response.data.organization
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    },

    goToOrganizations() {
      this.$router.push({ path: '/organizations' });
    },

    openModalAdd(data) {
      this.$refs.modalAdd.openModal(data)
    }
  },

  created() {
    this.getOrganization()
    this.getPromotions()
  }
}
</script>

<style>
.body-page {
  width: 100%;

  padding: 20px;

  border-radius: 10px;
  border: 1px solid gainsboro;
  background: white;
}
</style>