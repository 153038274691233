<template>
  <div id="body-login" class="d-flex justify-content-center align-items-center">
    <div class="box shadow">
      <div class="d-flex justify-content-center mt-5">
        <img src="@/assets/images/logo.png" />
      </div>
      <h5 class="fw-bold text-center my-3">SISTEMA DE GANHADORES</h5>

      <Divider class="mt-4" />

      <h6 class="mt-5 fw-bold">E-mail</h6>
      <InputText type="text" v-model="form.email" class="w-100" />

      <h6 class="mt-4 fw-bold">Senha</h6>
      <InputText @keypress.enter="login()" type="password" v-model="form.password" class="w-100" />

      <Button @click="login()" :label="buttons.login.label" class="p-button-success w-100 mt-5 mb-4 fw-bold" :disabled="buttons.login.disabled" />
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Divider from 'primevue/divider'

export default {
  name: 'Login',

  components: {
    InputText,
    Button,
    Divider
  },

  data() {
    return {
      form: {
        email: '',
        password: ''
      },

      buttons: {
        login: {
          label: 'Entrar',
          disabled: false
        }
      }
    }
  },

  methods: {
    async login() {
      this.buttons.login.label = 'Entrando...'
      this.buttons.login.disabled = true

      await this.$api.post('/auth/login', this.form)
        .then((response) => {
          localStorage.setItem('_token', response.data.token)
          this.$toastr.s('Logado com sucesso!', 'Sucesso')
          window.location.href = "/organizations"
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
          this.form.password = ''
        })
        .finally(() => {
          this.buttons.login.label = 'Entrar'
          this.buttons.login.disabled = false
        })
    }
  }
}
</script>

<style>
#body-login {
  width: 100%;
  height: 100vh;
}

.box {
  width: 500px;

  padding: 30px;
  border: 3px solid;
  border-image: linear-gradient(to bottom, #689F38, transparent) 1;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: white;
}
</style>