<template>
  <Dialog header="Ordenar Módulos" :visible.sync="display" :containerStyle="{ width: '600px' }" :modal="true">

    <OrderList v-model="winnerModules" listStyle="height:auto" dataKey="id" @reorder="onReorder">
      <template #header>
        Lista de Módulos
      </template>
      <template #item="slotProps">
        <div class="p-caritem">
          <span class="badge text-bg-secondary text-center me-2">{{ slotProps.index + 1 }}º</span> {{ slotProps.item.title }}
        </div>
      </template>
    </OrderList>

    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" @click="closeModal()" class="p-button-sm p-button-secondary" />
      <Button :label="buttons.reorder.label" icon="pi pi-list" @click="reorderWinnerModules(promotion.id)"
        class="p-button-sm p-button-primary" :disabled="buttons.reorder.disabled" />
    </template>
  </Dialog>
</template>
  
<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import OrderList from 'primevue/orderlist'

export default {
  name: 'ModalOrderModule',

  components: {
    Dialog,
    Button,
    OrderList
  },

  data() {
    return {
      promotion: {},
      winnerModules: [],
      display: false,

      form: {
        list: [],
      },

      buttons: {
        reorder: {
          label: 'Ordenar',
          disabled: false
        }
      },
    }
  },

  methods: {
    async getWinnerModules(id) {
      this.loading = true

      await this.$api.get(`/promotions/${id}/winner_modules`)
        .then((response) => {
          this.winnerModules = response.data.winner_modules
          this.loading = false
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    },

    async reorderWinnerModules(id) {
      this.buttons.reorder.label = 'Ordenando...'
      this.buttons.reorder.disabled = true

      await this.$api.put(`/promotions/${id}/winner_modules/reorder`, this.form)
        .then(() => {
          this.$toastr.s('Módulos ordenados com sucesso!', 'Sucesso')
          // this.closeModal()
          location.reload()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
        .finally(() => {
          this.buttons.reorder.label = 'Ordenar'
          this.buttons.reorder.disabled = false
        })
    },


    onReorder(event) {
      this.form.list = event.value
    },

    openModal(promotion) {
      this.promotion = promotion
      this.getWinnerModules(this.promotion.id)
      this.display = true
    },

    closeModal() {
      this.display = false
    },

    clearFields() {
      this.form.list = []
    }
  },

  watch: {
    display(value) {
      if (!value) {
        this.clearFields()
      }
    }
  }
}
</script>
  
<style></style>