<template>
  <div>
    <div class="d-flex justify-content-end">
      <Button @click="openModalEdit(winnerModule)" label="Editar" icon="pi pi-pencil" class="p-button-text p-button-rounded p-button-sm p-button-primary" />
      <Button @click="openModalDelete(winnerModule)" label="Excluir" icon="pi pi-trash" class="p-button-text p-button-rounded p-button-sm p-button-danger" />
    </div>

    <Fields :winnerModule="winnerModule" />
    <Table :winners="winnersTable" :columns="columnsTable" :loading="loading" :winnerModule="winnerModule" />

    <ModalEdit ref="modalEdit" />
    <ModalDelete ref="modalDelete" />
  </div>
</template>

<script>
import Button from 'primevue/button'
import Fields from '@/components/pages/winnerModules/Fields'
import Table from '@/components/pages/winners/Table'
import ModalEdit from '@/components/pages/winnerModules/ModalEdit'
import ModalDelete from '@/components/pages/winnerModules/ModalDelete'

export default {
  name: 'Module',

  props: ['winnerModule'],

  components: {
    Button,
    Fields,
    Table,
    ModalEdit,
    ModalDelete
  },

  data() {
    return {
      winners: [],
      loading: true,

      columnsTable: [],
      winnersTable: []
    }
  },

  methods: {
    async getWinners() {
      this.loading = true

      await this.$api.get(`/winner_modules/${this.winnerModule.id}/winners`)
        .then((response) => {
          this.winners = response.data.winners
          this.loading = false
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    },

    generateInfoTable() {
      this.columnsTable = []
      this.winnersTable = []

      this.winnerModule.fields.forEach((element, index) => {
        this.columnsTable.push({ field: `field_${index}`, header: element })
      })

      this.winners.forEach((element) => {
        const winner = {}
        winner['id'] = element.id

        element.winner.forEach((e, i) => {
          winner[`field_${i}`] = e
        })
        
        this.winnersTable.push(winner)
      })
    },

    openModalEdit(data) {
      this.$refs.modalEdit.openModal(data)
    },

    openModalDelete(data) {
      this.$refs.modalDelete.openModal(data)
    }
  },

  watch: {
    winners() {
      this.generateInfoTable()
    }
  },

  created() {
    this.getWinners()
  }
}
</script>

<style></style>