<template>
  <Dialog header="Editar Módulo" :visible.sync="display" :containerStyle="{ width: '600px' }" :modal="true">
    <div class="col-12 mb-4">
      <h6>Título</h6>
      <InputText type="text" v-model="form.title" class="w-100" />
    </div>

    <div class="row mb-4">
      <div class="col-5">
        <div v-for="(field, index) in list.fields" :key="index" class="mb-2">
          <h6>Campo {{ index + 1 }}</h6>
          <InputText type="text" v-model="field.value" class="w-100" />
        </div>
      </div>

      <div class="col-5">
        <div v-for="(rule, index) in list.rules" :key="index" class="mb-2">
          <h6>Regra {{ index + 1 }}</h6>
          <Dropdown v-model="rule.value" :options="rules" optionLabel="name" placeholder="Selecione..." class="w-100" />
        </div>
      </div>

      <div class="col-2">
        <div v-for="(field, index) in list.fields" :key="index" class="mb-2">
          <h6 class="text-center text-white">.</h6>
          <div class="d-flex justify-content-center">
            <Button @click="removeField(index)" icon="pi pi-times" class="p-button-danger" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mb-5">
      <h6>Filtro [{{ form.filter ? 'Ativo' : 'Desativado' }}]</h6>
      <InputSwitch v-model="form.filter" />
    </div>

    <template #footer>
      <Button @click="addField()" label="Adiconar Campo" icon="pi pi-plus" class="p-button-sm p-button-warning" />
      <Button label="Cancelar" icon="pi pi-times" @click="closeModal()" class="p-button-sm p-button-secondary" />
      <Button :label="buttons.edit.label" icon="pi pi-check" @click="editWinnerModule(winnerModule.id)" class="p-button-sm p-button-primary" :disabled="buttons.edit.disabled" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'

export default {
  name: 'ModalEdit',

  components: {
    Dialog,
    Button,
    InputText,
    InputSwitch,
    Dropdown
  },

  data() {
    return {
      winnerModule: {},

      form: {
        title: '',
        fields: [],
        rules: [],
        filter: false
      },

      list: {
        fields: [],
        rules: []
      },

      buttons: {
        edit: {
          label: 'Editar',
          disabled: false
        }
      },

      rules: [],
      display: false
    }
  },

  methods: {
    addField() {
      this.list.fields.push({ value: '' })
      this.list.rules.push({ value: '' })
    },

    removeField(index) {
      if (this.list.fields.length > 1) {
        this.list.fields.splice(index, 1)
        this.list.rules.splice(index, 1)
      }
      else {
        this.$toastr.w('O módulo deve ter pelo menos um campo!', 'Aviso')
      }
    },

    async editWinnerModule(id) {
      this.formatFieldsAndRules()

      this.buttons.edit.label = 'Editando...'
      this.buttons.edit.disabled = true

      await this.$api.put(`/winner_modules/${id}`, this.form)
        .then((response) => {
          this.$parent.$parent.$parent.$parent.winnerModules = this.$parent.$parent.$parent.$parent.winnerModules.map(obj => obj.id === id ? response.data.winner_module : obj);
          this.$toastr.s('Módulo editado com sucesso!', 'Sucesso')
          this.closeModal()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
        .finally(() => {
          this.buttons.edit.label = 'Editar'
          this.buttons.edit.disabled = false
        })
    },

    openModal(data) {
      this.winnerModule = data
      this.form.title = data.title
      this.form.fields = data.fields
      this.form.rules = data.rules
      this.form.filter = data.filter
      this.display = true
      this.convertFieldsAndRules()
    },

    closeModal() {
      this.display = false
    },

    convertFieldsAndRules() {
      this.list.fields = []
      this.list.rules = []

      this.form.fields.forEach(element => {
        this.list.fields.push({ value: element })
      });

      this.form.rules.forEach(element => {
        const findCode = this.rules.find(obj => obj.code == element);

        this.list.rules.push({ 
          value: {
            code: element,
            name: findCode ? findCode.name : ''
          }
        })
      });
    },

    formatFieldsAndRules() {
      this.form.fields = []
      this.form.rules = []

      this.list.fields.forEach(element => {
        this.form.fields.push(element.value)
      });

      this.list.rules.forEach(element => {
        this.form.rules.push(element.value.code)
      });
    },

    async getRules() {
      await this.$api.get('/select/rules')
        .then((response) => {
          this.rules = response.data
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    },
  },

  created() {
    this.getRules()
  }
}
</script>

<style></style>