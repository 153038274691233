<template>
  <div id="not-found">
    <h1>404</h1>
    <p class="text-not-found">Lamentamos, mas a página que você procura não existe.</p>
    <a href="/">Voltar para a página inicial</a>
  </div>

</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style>
#not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.text-not-found {
  font-size: 1.5rem;
  margin: 1rem 0 0;
  color: #666;
}
</style>