<template>
  <div class="body-page">
    <h6 class="fw-bold mb-4">[{{ promotion.name }}] Módulos de ganhadores</h6>
    <div class="d-flex justify-content-end mb-3">
      <Button @click="goToOrganization(promotion.organization_id)" label="Voltar" icon="pi pi-arrow-left" class="p-button-secondary p-button-sm me-2" />
      <Button @click="openModalOrderModule(promotion)" label="Ordenar" icon="pi pi-list" class="p-button-primary p-button-sm me-2" />
      <Button @click="openModalAdd(promotion)" label="Adicionar Módulo" icon="pi pi-box" class="p-button-success p-button-sm" />
    </div>

    <TabView :scrollable="true">
      <TabPanel v-for="(winnerModule, n) in winnerModules" :key="n" :header="winnerModule.title">
        <Module :winnerModule="winnerModule" />
      </TabPanel>
    </TabView>
    
    <h6 v-if="winnerModules.length == 0 && loading == false" class="text-center">Esta promoção não possui nenhum módulo</h6>

    <ModalAdd ref="modalAdd" />
    <ModalOrderModule ref="modalOrderModule" />
  </div>
</template>

<script>
import Button from 'primevue/button'
import ModalOrderModule from '@/components/pages/promotions/ModalOrderModule'
import ModalAdd from '@/components/pages/winnerModules/ModalAdd'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Module from '@/components/pages/winnerModules/Module'

export default {
  name: 'Promotion',

  props: ['id'],

  components: {
    Button,
    ModalOrderModule,
    ModalAdd,
    TabView,
    TabPanel,
    Module
  },

  data() {
    return {
      promotion: null,
      winnerModules: [],
      loading: true
    }
  },

  methods: {
    async getWinnerModules() {
      this.loading = true

      await this.$api.get(`/promotions/${this.id}/winner_modules`)
        .then((response) => {
          this.winnerModules = response.data.winner_modules
          this.loading = false
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    },

    async getPromotion() {
      await this.$api.get(`/promotions/${this.id}`)
        .then((response) => {
          this.promotion = response.data.promotion
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    },

    goToOrganization(id) {
      this.$router.push({ path: `/organizations/${id}` });
    },

    openModalAdd(data) {
      this.$refs.modalAdd.openModal(data)
    },

    openModalOrderModule(promotion) {
      this.$refs.modalOrderModule.openModal(promotion)
    }
  },

  created() {
    this.getPromotion()
    this.getWinnerModules() 
  }
}
</script>

<style>
.body-page {
  width: 100%;

  padding: 20px;

  border-radius: 10px;
  border: 1px solid gainsboro;
  background: white;
}
</style>