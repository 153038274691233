<template>
  <div id="layout">
    <Top />
    <div id="body-layout">
      <router-view />
    </div>
  </div>
</template>

<script>
import Top from '@/components/layouts/Top'

export default {
  name: 'Layout',

  components: {
    Top
  }
}
</script>

<style lang="scss">
#layout {
  padding: 20px;
}

#body-layout {
  width: 100%;
  height: calc(100vh - 130px);

  margin-top: 20px;
  overflow-y: auto;
}
</style>