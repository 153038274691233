import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config'
import api from './services/axios'
import VueToastr from "vue-toastr"
import ConfirmationService from 'primevue/confirmationservice'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.use(PrimeVue)
Vue.use(VueToastr)
Vue.use(ConfirmationService)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
