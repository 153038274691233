<template>
  <div>
    <div class="d-flex justify-content-end mt-5 mb-3 border-top pt-5">
      <Button @click="$parent.getWinners()" icon="pi pi-sync" class="p-button-warning p-button-sm me-2" />
      <Button @click="openModalDelete(winnerModule)" label="Excluir Ganhadores" icon="pi pi-trash" class="p-button-danger p-button-outlined p-button-sm me-2" />
      <Button @click="openModalAddFile(winnerModule)" label="Adicionar Ganhadores (.CSV)" icon="pi pi-file" class="p-button-success p-button-sm me-2" />
    </div>

    <DataTable 
      :value="winners"
      :paginator="true"
      :rows="20"
      :rowsPerPageOptions="[10, 20, 50, 100, 200]"
      :scrollable="true"
      :loading="loading"
      :filters="filters"
      
      class="p-datatable-sm"
      showGridlines
      responsiveLayout="scroll"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
      scrollHeight="800px"
      dataKey="id"
    >
      <template #header>
        <div class="d-flex justify-content-end">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Procurar..." />
            </span>
        </div>
      </template>
      <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"></Column>
      <Column field="id" header="ID" :styles="{'min-width': '80px', 'max-width': '80px'}">
        <template #body="slotProps">
          <b>{{ slotProps.data.id }}</b>
        </template>
      </Column>
    </DataTable>

    <ModalAddFile ref="modalAddFile" />
    <ModalDelete ref="modalDelete" />
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import ModalAddFile from '@/components/pages/winners/ModalAddFile'
import ModalDelete from '@/components/pages/winners/ModalDelete'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Table',

  props: ['winners', 'columns', 'loading', 'winnerModule'],

  components: {
    DataTable,
    Column,
    Button,
    ModalAddFile,
    ModalDelete,
    InputText
  },

  data() {
    return {
      filters: {},
    }
  },

  methods: {
    openModalAddFile(data) {
      this.$refs.modalAddFile.openModal(data)
    },

    openModalDelete(data) {
      this.$refs.modalDelete.openModal(data)
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  },

  created() {
    this.initFilters()
  }
}
</script>

<style></style>