import Vue from 'vue'
import VueRouter from 'vue-router'
import middleware from '@/services/middleware'

import Layout from '@/components/layouts/Layout'
import Login from '@/views/Login'
import Users from '@/views/Users'
import Organizations from '@/views/Organizations'
import Organization from '@/views/Organization'
import Promotion from '@/views/Promotion'
import Logs from '@/views/Logs'
import NotFound from '@/components/layouts/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    beforeEnter: middleware.auth,
    children: [
      {
        path: '/',
        redirect: '/login'
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
        beforeEnter: middleware.admin
      },
      {
        path: '/organizations',
        name: 'Organizations',
        component: Organizations
      },
      {
        path: '/organizations/:id',
        name: 'Organization',
        component: Organization,
        props: true
      },
      {
        path: '/promotions/:id',
        name: 'Promotion',
        component: Promotion,
        props: true
      },
      {
        path: '/logs',
        name: 'Logs',
        component: Logs,
        beforeEnter: middleware.admin
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
