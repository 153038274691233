<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th v-for="(field, n) in winnerModule.fields" :key="n" scope="col">{{ field }}</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(winnerAux, n) in winnersAux" :key="n" :class="winnerAux.status == 'Auditoria' ? 'table-warning' : ''">
          <th>{{ n + 1 }}</th>
          <td v-for="(field, n) in winnerAux.winner" :key="n">{{ field }}</td>
          <td>{{ winnerAux.status }}</td>
        </tr>

        <tr v-if="loading == false && winnersAux.length == 0" colspan="2">
          <td :colspan="winnerModule.fields.length + 2" class="text-center">Não há ganhadores para publicar</td>
        </tr>
        <tr v-if="loading == true">
          <td :colspan="winnerModule.fields.length + 2" class="text-center">Carregando...</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableOriginal',

  props: ['winnerModule'],

  data() {
    return {
      winnersAux: [],
      loading: true,
    }
  },

  methods: {
    async getWinnersAux() {
      this.loading = true

      await this.$api.get(`/winner_modules/${this.winnerModule.id}/winners_aux`)
        .then((response) => {
          this.winnersAux = response.data.winners_aux
          this.loading = false
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    }
  },

  created() {
    this.getWinnersAux()
  }
}
</script>

<style></style>