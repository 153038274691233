<template>
  <div>
    <div id="top-layout" class="d-flex shadow-sm justify-content-between align-items-center">
      <div class="d-flex align-items-center justify-content-center">
        <i @click="sideBar = true" class="pi pi-bars me-3 hover" style="font-size: 22px"></i>
        <img @click="goTo('/organizations')" src="@/assets/images/logo.png" class="hover" />
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center d-none d-md-block">
          {{ $store.state.user.name }}
          <Avatar icon="pi pi-user" class="mx-2 hover" shape="circle" />
        </div>
        <Button @click="logout()" label="Sair" icon="pi pi-sign-out" class="p-button-link p-button-sm" />
      </div>
    </div>

    <Sidebar :visible.sync="sideBar">
      <h5 class="fw-bold">Menu</h5>
      <div class="d-flex flex-column mt-4 mx-2">
        <div><Button @click="goTo('/organizations')" icon="pi pi-building" label="Organizações" class="p-button-secondary p-button-text p-button-outlined w-100 text-start" /></div>
        <div><Button v-if="$store.state.user.is_admin" @click="goTo('/users')" icon="pi pi-user" label="Usuários" class="p-button-secondary p-button-text p-button-outlined w-100 text-start" /></div>
        <div><Button v-if="$store.state.user.is_admin" @click="goTo('/logs')" icon="pi pi-book" label="Histórico" class="p-button-secondary p-button-text p-button-outlined w-100 text-start" /></div>
      </div>
    </Sidebar>
  </div>
</template>

<script>
import Avatar from 'primevue/avatar'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'

export default {
  name: 'Top',

  components: {
    Avatar,
    Button,
    Sidebar
  },

  data() {
    return {
      sideBar: false,
    }
  },

  methods: {
    logout() {
      localStorage.setItem('_token', '')
      window.location.href = "/login"
    },

    goTo($router) {
      window.location.href = $router
    }
  }
}
</script>

<style lang="scss">
#top-layout {
  width: 100%;
  height: 70px;

  padding-left: 20px;
  padding-right: 20px;

  border-radius: 10px;
  border: 1px solid gainsboro;
  background: white;
}
</style>