<template>
  <div>
    <DataTable 
      :value="logs"
      :paginator="true"
      :rows="100"
      :rowsPerPageOptions="[10, 20, 50, 100, 200]"
      :scrollable="true"
      :loading="loading"
      :filters="filters"
      
      class="p-datatable-sm"
      showGridlines
      responsiveLayout="scroll"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
      scrollHeight="700px"
      dataKey="id"
    >
      <template #header>
        <div class="d-flex justify-content-end">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Procurar..." />
            </span>
        </div>
      </template>
      <Column field="id" header="ID" :styles="{'min-width': '100px', 'max-width': '100px'}">
        <template #body="slotProps">
          <b>{{ slotProps.data.id }}</b>
        </template>
      </Column>
      <Column field="created_at" header="Data" :styles="{'min-width': '150px', 'max-width': '150px'}">
        <template #body="slotProps">
          {{ slotProps.data.created_at }}
        </template>
      </Column>
      <Column field="event" header="Evento" :styles="{'min-width': '230px'}"></Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Table',

  props: ['logs', 'loading'],

  components: {
    DataTable,
    Column,
    InputText
  },

  data() {
    return {
      filters: {},
    }
  },

  methods: {
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  },

  created() {
    this.initFilters()
  }
}
</script>

<style></style>