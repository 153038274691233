<template>
  <div>
    <Dialog :header="'Adicionar Ganhadores (.CSV) - [' + winnerModule.title + ']'" :visible.sync="display" :containerStyle="{ width: '100%', margin: '10px' }" :modal="true">
      
      <h6 class="mt-4">Selecione o arquivo .csv</h6>
      <div class="input-group">
        <input ref="file" class="form-control" type="file" accept="text/csv" @change="onUpload" style="max-width: 400px;">
      </div>
      <i style="font-size: 13px;">Após adicionar o arquivo os ganhadores não irão direto para a produção. Para isto, é necessário publicá-los!</i>
      
      <TabView class="mt-5">
        <TabPanel header="Original">
          <TableOriginal ref="tableOriginal" :winnerModule="winnerModule" />
        </TabPanel>
        <TabPanel header="Visualização">
          <TableView ref="tableView" :winnerModule="winnerModule" />
        </TabPanel>
      </TabView>

      <template #footer>
        <Button label="Fechar" icon="pi pi-times" @click="closeModal()" class="p-button-sm p-button-secondary" />
        <Button :label="buttons.delete.label" icon="pi pi-trash" @click="deleteWinnersAux()" class="p-button-sm p-button-outlined p-button-danger me-2" :disabled="buttons.delete.disabled" />
        <Button :label="buttons.approve.label" icon="pi pi-check" @click="approveWinnersAux()" class="p-button-sm p-button-help me-2" :disabled="buttons.approve.disabled" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import TableOriginal from '@/components/pages/winners/TableOriginal'
import TableView from '@/components/pages/winners/TableView'

export default {
  name: 'ModalAddFile',

  components: {
    TabView,
    TabPanel,
    Dialog,
    Button,
    TableOriginal,
    TableView
  },

  data() {
    return {
      winnerModule: {},

      form: {
        file: null,
      },

      buttons: {
        approve: {
          label: 'Publicar',
          disabled: false
        },

        delete: {
          label: 'Limpar',
          disabled: false
        }
      },

      display: false
    }
  },

  methods: {
    async addWinnersAux() {

      const formData = new FormData()
      formData.append('file', this.form.file)

      await this.$api.post(`/winner_modules/${this.winnerModule.id}/winners_csv`, formData)
        .then(() => {
          this.$refs.tableOriginal.getWinnersAux()
          this.$refs.tableView.getWinnersAux()
          this.$toastr.s('Ganhadores adicionados com sucesso!', 'Sucesso')
          this.clearFields()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    },

    async approveWinnersAux() {
      this.buttons.approve.label = 'Publicando...'
      this.buttons.approve.disabled = true

      await this.$api.put(`/winner_modules/${this.winnerModule.id}/winners_approve`)
        .then(() => {
          this.$parent.$parent.getWinners()
          this.$toastr.s('Ganhadores publicados com sucesso!', 'Sucesso')
          this.closeModal()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
        .finally(() => {
          this.buttons.approve.label = 'Publicar'
          this.buttons.approve.disabled = false
        })
    },

    async deleteWinnersAux() {
      this.buttons.delete.label = 'Limpando...'
      this.buttons.delete.disabled = true

      await this.$api.delete(`/winner_modules/${this.winnerModule.id}/winners_aux`)
        .then(() => {
          this.$refs.tableOriginal.getWinnersAux()
          this.$refs.tableView.getWinnersAux()
          this.$toastr.s('Ganhadores excluidos com sucesso!', 'Sucesso')
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
        .finally(() => {
          this.buttons.delete.label = 'Limpar'
          this.buttons.delete.disabled = false
        })
    },

    openModal(data) {
      this.winnerModule = data
      this.display = true
    },

    closeModal() {
      this.display = false
    },

    clearFields() {
      this.form.file = null
      this.$refs.file.value = null
    },

    onUpload(event) {
      this.form.file = event.target.files[0]
      this.addWinnersAux()
    }
  },

  watch: {
    display(value) {
      if (!value) {
        this.clearFields()
      }
    }
  }
}
</script>

<style></style>