<template>
  <div class="my-4">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th :colspan="winnerModule.fields.length" class="text-center table-secondary border text-secondary">
              Campos e Regras
            </th>
          </tr>
          <tr>
            <th v-for="(field, n) in winnerModule.fields" :key="n">
              {{ field }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(rule, n) in winnerModule.rules" :key="n">
              {{ findRule(rule) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-end">
      Este módulo está com o filtro:
      <span v-if="winnerModule.filter" class="badge text-bg-info ms-2">Ativo</span>
      <span v-else class="badge text-bg-dark ms-2">Desativado</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Fields',

  props: ['winnerModule'],

  data() {
    return {
      rules: []
    }
  },

  methods: {
    findRule(rule) {
      const findCode = this.rules.find(obj => obj.code == rule);
      return findCode ? findCode.name : ''
    },

    async getRules() {
      await this.$api.get('/select/rules')
        .then((response) => {
          this.rules = response.data
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
    },
  },

  created() {
    this.getRules()
  },
}
</script>

<style></style>