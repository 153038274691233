<template>
  <Dialog header="Editar Organização" :visible.sync="display" :containerStyle="{ width: '430px' }" :modal="true">
    <div class="col-12 mb-4">
      <h6>Nome da organização</h6>
      <InputText type="text" v-model="form.name" class="w-100" />
    </div>

    <div class="col-12 mb-5">
      <h6>Situação [{{ form.active? 'Ativa' : 'Desativada' }}]</h6>
      <InputSwitch v-model="form.active" />
    </div>

    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" @click="closeModal()" class="p-button-sm p-button-secondary" />
      <Button :label="buttons.edit.label" icon="pi pi-check" @click="editOrganization(organizationId)" class="p-button-sm p-button-primary" :disabled="buttons.edit.disabled" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'

export default {
  name: 'ModalEdit',

  components: {
    Dialog,
    Button,
    InputText,
    InputSwitch
  },

  data() {
    return {
      organizationId: 0,

      form: {
        name: '',
        active: '',
      },

      buttons: {
        edit: {
          label: 'Editar',
          disabled: false
        }
      },

      display: false
    }
  },

  methods: {
    async editOrganization(id) {
      this.buttons.edit.label = 'Editando...'
      this.buttons.edit.disabled = true

      await this.$api.put(`/organizations/${id}`, this.form)
        .then((response) => {
          this.$parent.$parent.organizations = this.$parent.$parent.organizations.map(obj => obj.id === id ? response.data.organization : obj);
          this.$toastr.s('Organização editada com sucesso!', 'Sucesso')
          this.closeModal()     
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
        .finally(() => {
          this.buttons.edit.label = 'Editar'
          this.buttons.edit.disabled = false
        })
    },

    openModal(data) {
      this.organizationId = data.id
      this.form.name = data.name
      this.form.active = data.active
      this.display = true
    },

    closeModal() {
      this.display = false
    }
  }
}
</script>

<style></style>