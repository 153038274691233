<template>
  <div>
    <DataTable 
      :value="promotions"
      :paginator="true"
      :rows="20"
      :rowsPerPageOptions="[10, 20, 50, 100, 200]"
      :scrollable="true"
      :loading="loading"
      :filters="filters"
      
      class="p-datatable-sm"
      showGridlines
      responsiveLayout="scroll"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
      scrollHeight="800px"
      dataKey="id"
    >
      <template #header>
        <div class="d-flex justify-content-end">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Procurar..." />
            </span>
        </div>
      </template>
      <Column field="id" header="ID" :styles="{'min-width': '80px', 'max-width': '80px'}">
        <template #body="slotProps">
          <b>{{ slotProps.data.id }}</b>
        </template>
      </Column>
      <Column field="name" header="Nome" :styles="{'min-width': '230px'}"></Column>
      <Column field="uid" header="UID" :styles="{'min-width': '250px', 'max-width': '250px'}">
        <template #body="slotProps">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText :value="slotProps.data.uid" class="p-inputtext-sm bg-light" readonly />
              <Button icon="pi pi-copy" class="p-button-secondary" @click="copyUID(slotProps.data)" />
            </div>
          </div>
        </template>
      </Column>
      <Column field="active" header="Situação" :styles="{'min-width': '120px', 'max-width': '120px'}">
        <template #body="slotProps">
          <span v-if="slotProps.data.active" class="badge text-bg-success">Ativa</span>
          <span v-else class="badge text-bg-danger">Desativada</span>
        </template>
      </Column>
      <Column  header="Ações" :styles="{ 'min-width': '330px', 'max-width': '330px'}">
        <template #body="slotProps">
          <div class="d-flex justify-content-around">
            <Button @click="goToModules(slotProps.data.id)" label="Módulos" icon="pi pi-box" class="p-button-text p-button-rounded p-button-sm p-button-help" />
            <Button @click="openModalEdit(slotProps.data)" label="Editar" icon="pi pi-pencil" class="p-button-text p-button-rounded p-button-sm p-button-primary" />
            <Button @click="openModalDelete(slotProps.data)" icon="pi pi-trash" label="Excluir" class="p-button-text p-button-rounded p-button-sm p-button-danger" />
          </div>
        </template>
      </Column>
    </DataTable>

    <ModalEdit ref="modalEdit" />
    <ModalDelete ref="modalDelete"  />
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import ModalEdit from '@/components/pages/promotions/ModalEdit'
import ModalDelete from '@/components/pages/promotions/ModalDelete'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Table',

  props: ['promotions', 'loading', 'organization'],

  components: {
    DataTable,
    Column,
    Button,
    ModalEdit,
    ModalDelete,
    InputText
  },

  data() {
    return {
      filters: {},
    }
  },

  methods: {
    openModalEdit(data) {
      this.$refs.modalEdit.openModal(data)
    },

    openModalDelete(data) {
      this.$refs.modalDelete.openModal(data)
    },

    goToModules(id) {
      this.$router.push({ path: `/promotions/${id}` });
    },

    copyUID(data) {
      const elementTemp = document.createElement('textarea')
      elementTemp.value = data.uid
      document.body.appendChild(elementTemp)
      elementTemp.select()
      document.execCommand('copy')
      document.body.removeChild(elementTemp)
      this.$toastr.i(`UID da promoção ${data.name} copiado!`, 'Informação')
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  },

  created() {
    this.initFilters();
  }
}
</script>

<style></style>