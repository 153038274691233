<template>
  <Dialog header="Adicionar Organização" :visible.sync="display" :containerStyle="{ width: '430px' }" :modal="true">
    <div class="col-12 mb-4">
      <h6>Nome da organização</h6>
      <InputText type="text" v-model="form.name" class="w-100" />
    </div>

    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" @click="closeModal()" class="p-button-sm p-button-secondary" />
      <Button :label="buttons.add.label" icon="pi pi-check" @click="addOrganization()" class="p-button-sm p-button-success" :disabled="buttons.add.disabled" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'

export default {
  name: 'ModalAdd',

  components: {
    Dialog,
    Button,
    InputText,
  },

  data() {
    return {
      form: {
        name: '',
      },

      buttons: {
        add: {
          label: 'Adicionar',
          disabled: false
        }
      },

      display: false
    }
  },

  methods: {
    async addOrganization() {
      this.buttons.add.label = 'Adicionando...'
      this.buttons.add.disabled = true

      await this.$api.post('/organizations', this.form)
        .then((response) => {
          this.$parent.organizations.unshift(response.data.organization)
          this.$toastr.s('Organização adicionada com sucesso!', 'Sucesso')
          this.closeModal()     
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
        .finally(() => {
          this.buttons.add.label = 'Adicionar'
          this.buttons.add.disabled = false
        })
    },

    openModal() {
      this.display = true
    },

    closeModal() {
      this.display = false
    },

    clearFields() {
      this.form.name = ''
    }
  },

  watch: {
    display(value) {
      if (!value) {
        this.clearFields()
      }
    }
  }
}
</script>

<style></style>